<template>
  <v-autocomplete
    class="c-input-xs"
    v-model="value"
    :items="options"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    @keyup.enter="onFilter"
    multiple
    dense
    outlined
    clearable
    hide-details
    single-line
  >
    <template v-slot:prepend-item>
      <v-list-item
          ripple
          @mousedown.prevent
          @click="toggle"
      >
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('labels.all') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <template v-if="likesAllOption">
        <v-chip v-if="index === 0">
          <span>{{ $t('labels.all') }}</span>
        </v-chip>
      </template>
      <template v-else>
        <v-chip v-if="index === 0">
          <span>{{ item.text }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ value.length - 1 }})
        </span>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import {httpClient} from "@/libs/http";

export default {
  name: 'SelectMultipleEmployeeByPos',
  props: {
    name: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    idPoss: {
      type: [String, Number, Array],
      default: () => ''
    },
    defaultValue: {
      type: [String, Number, Array],
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    value: [],
    filters: {},
    options: [],
  }),
  watch: {
    value() {
      this.onFilter()
    },
    idPoss() {
      this.getList()
    }
  },
  computed: {
    likesAllOption () {
      return this.value.length === this.options.length
    },
    likesSomeOption () {
      return this.value.length > 0 && !this.likesAllOption
    },
    icon () {
      if (this.likesAllOption) return 'mdi-close-box'
      if (this.likesSomeOption) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  mounted() {
    this.getList()
    // this.value = [...this.defaultValue]
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllOption) {
          this.value = []
        } else {
          this.value = this.options.map(o => (o.value))
        }
      })
    },
    onFilter() {
      this.$emit('onFilter', {
        name: this.name,
        value: this.value
      })
    },
    async getList() {
      this.options = []
      this.value = []
      const {data} = await httpClient.post('/employee-active-my-pos', {id_poss: this.idPoss})
      const options = data.map(e => ({
        value: e.id,
        text: e.full_name || ''
      }))
      if (this.defaultValue.includes('all')) {
        const value = [...options].map(o => o.value)
        this.value = [...value]
      }
      this.options = [...options]
    }
  },
}
</script>
